import { Loading } from '@shopify/polaris';
import { useEffect } from 'react';
import useRouter from '../hooks/useRouter';

export default function HomePage() {
  const router = useRouter();

  useEffect(() => {
    router.replace('/dashboard');
  }, [router]);

  return <Loading />;
}

export const getServerSideProps = async (ctx) => {
  return {
    props: {},
  };
};
